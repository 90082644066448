@charset "UTF-8";
/**
 * mixins
 */
/* ▼モバイルファースト (全環境に共通のデザイン) */
.l-main .mv-wrap {
  padding-top: 10px;
  margin-bottom: 12px;
  background: #fff;
}

.l-main .mv-wrap img {
  border-radius: 0 0 56px 0;
}

.l-main .search-wrap {
  padding: 15px 0;
}

.l-main .search-wrap .c-roundbox {
  margin-bottom: 30px;
  padding-bottom: 25px;
}

.l-main .search-wrap .c-roundbox .c-title {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
  padding: 10px 20px 15px;
  border-bottom: 1px solid #f4f4f4;
}

.l-main .search-wrap .c-roundbox .c-title .icon {
  padding-right: 5px;
}

.l-main .search-wrap .c-roundbox .c-title .icon img {
  max-width: 34px;
  max-height: 30px;
  vertical-align: middle;
}

.l-main .search-wrap .c-roundbox .label-search {
  display: flex;
  border-radius: 10em;
  height: 48px;
}

.l-main .search-wrap .c-roundbox .label-search span {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 0 0 15px;
  background: #fff;
  border-radius: 10em 0 0 10em;
  border-right: 1px solid #e7e7e7;
  border: 1px solid #e7e7e7;
  width: 98px;
  font-size: 1.14286rem;
  font-weight: bold;
}

.l-main .search-wrap .c-roundbox .label-search span img {
  width: 15px;
  margin-right: 8px;
}

.l-main .search-wrap .c-roundbox .label-search input {
  width: calc(100% - 98px);
  height: 48px;
  border: none;
  outline: none;
  background: #f4f4f4;
  padding: 10px;
  border-radius: 0 10em 10em 0;
  border: 1px solid #e7e7e7;
  border-left: 0;
  font-size: 1.14286rem;
}

.l-main .search-wrap .c-roundbox .label-search.departure {
  margin-bottom: 32px;
  position: relative;
}

.l-main .search-wrap .c-roundbox .label-search.departure:after {
  position: absolute;
  display: block;
  content: "";
}

.l-main .search-wrap .c-roundbox .label-search.departure:after {
  left: 50px;
  top: 100%;
  width: 12px;
  height: 30px;
  background: url(../img/common/icon_down.png) no-repeat center top/contain;
}

.l-main .search-wrap .c-roundbox .btn-wrap {
  margin-top: 30px;
}

.l-main .search-wrap .c-roundbox .li-trafic {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.l-main .search-wrap .c-roundbox .li-trafic li {
  width: calc(33.3333% + 1px);
  margin-left: -1px;
  border: 1px solid #cbdfc0;
  border-top: 0;
}

.l-main .search-wrap .c-roundbox .li-trafic li:nth-child(1) {
  border-top: 1px solid #cbdfc0;
  border-top-left-radius: 4px;
}

.l-main .search-wrap .c-roundbox .li-trafic li:nth-child(2) {
  border-top: 1px solid #cbdfc0;
}

.l-main .search-wrap .c-roundbox .li-trafic li:nth-child(3) {
  border-top: 1px solid #cbdfc0;
  border-top-right-radius: 4px;
}

.l-main .search-wrap .c-roundbox .li-trafic li:nth-child(7) {
  border-bottom-left-radius: 4px;
}

.l-main .search-wrap .c-roundbox .li-trafic li:nth-child(9) {
  border-bottom-right-radius: 4px;
}

.l-main .search-wrap .c-roundbox .li-trafic li a {
  position: relative;
  display: block;
  padding: 7px 8px 5px;
}

.l-main .search-wrap .c-roundbox .li-trafic li a:after {
  position: absolute;
  display: block;
  content: "";
}

.l-main .search-wrap .c-roundbox .li-trafic li a.blank:after {
  right: 5px;
  bottom: 5px;
  width: 21px;
  height: 21px;
  border: 1px solid #cbdfc0;
  border-radius: 50%;
  background: url(../img/common/icon_window_gr.png) no-repeat center center/9px 9px;
}

.l-main .search-wrap .c-roundbox .li-trafic li .txt-mark {
  padding-top: 10px;
  text-align: center;
}

.l-main .search-wrap .c-roundbox .li-trafic li .txt-mark img {
  width: 51px;
}

.l-main .search-wrap .c-roundbox .li-trafic li .txt-num {
  margin-top: 0;
  color: #b5b5b5;
  font-family: "Ubuntu", "游ゴシック体", YuGothic, "Yu Gothic Medium", "Yu Gothic UI", sans-serif;
  font-weight: bold;
  font-style: italic;
  font-size: 1.14286rem;
  text-align: center;
}

.l-main .search-wrap .c-roundbox .li-trafic li .txt-num span {
  font-family: "游ゴシック体", YuGothic, "Yu Gothic Medium", "Yu Gothic UI", sans-serif;
  font-size: 0.85714rem;
}

.l-main .search-wrap .c-roundbox .li-trafic li .txt-area {
  margin-top: 0;
  font-weight: bold;
  font-size: 0.92857rem;
}

.l-main .search-wrap .c-roundbox .li-trafic li.stop a {
  color: #bd1a1a;
}

.l-main .search-wrap .c-roundbox .li-trafic li.stop a .txt-num {
  color: #bd1a1a;
}

.l-main .search-wrap .c-roundbox .li-trafic li.stop a.blank:after {
  border-color: #ea9191;
  background-image: url(../img/common/icon_window_rd.png);
}

.l-main .search-wrap .c-roundbox .box-trafic {
  padding-bottom: 25px;
}

.l-main .search-wrap .c-roundbox .box-trafic .txt {
  font-size: 1.14286rem;
  font-weight: bold;
}

.l-main .search-wrap .c-roundbox .box-trafic .txt-date {
  margin-top: 0;
  color: #b5b5b5;
  font-size: 0.85714rem;
  line-height: 1;
}

.l-main .search-wrap .c-roundbox .li-servicearea .ttl {
  font-size: 1.14286rem;
  font-weight: bold;
}

.l-main .search-wrap .c-roundbox .li-servicearea .box-radio {
  display: flex;
  margin-top: 30px;
}

.l-main .search-wrap .c-roundbox .li-servicearea .box-radio .ttl {
  margin-right: 15px;
}

.l-main .search-wrap .c-roundbox .li-servicearea .label-radio {
  display: inline-block;
  min-width: 30%;
  font-weight: bold;
}

.l-main .search-wrap .c-roundbox .li-servicearea .label-radio input[type="radio"] {
  display: none;
}

.l-main .search-wrap .c-roundbox .li-servicearea .label-radio input[type="radio"] + span {
  position: relative;
  padding-left: 25px;
  display: inline-block;
  line-height: 1;
}

.l-main .search-wrap .c-roundbox .li-servicearea .label-radio input[type="radio"] + span:before {
  position: absolute;
  display: block;
  content: "";
}

.l-main .search-wrap .c-roundbox .li-servicearea .label-radio input[type="radio"] + span:before {
  top: -2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #cbdfc0;
  background: #f4f4f4;
}

.l-main .search-wrap .c-roundbox .li-servicearea .label-radio input[type="radio"]:checked + span:before {
  border: 3px solid #f4f4f4;
  background: #2d720a;
}

.l-main .search-wrap .c-roundbox .li-servicearea .label-radio + .label-radio {
  margin-left: 10px;
}

.l-main .search-wrap .box-sa {
  padding-bottom: 60px;
}

.l-main .infomation-wrap {
  padding: 50px 0;
}

.l-main .infomation-wrap .c-roundbox {
  padding-bottom: 60px;
}

.l-main .infomation-wrap .c-roundbox .c-title {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
  padding: 10px 20px 20px;
  border-bottom: 1px solid #f4f4f4;
}

.l-main .infomation-wrap .li-infomation li + li {
  margin-top: 30px;
}

.l-main .news-wrap {
  padding: 25px 0;
}

.l-main .news-wrap .c-title {
  margin-bottom: 30px;
}

.l-main .news-wrap .c-roundbox {
  padding-top: 12px;
  padding-bottom: 20px;
}

.l-main .news-wrap .c-roundbox .c-title.has-en {
  margin-bottom: 10px;
  color: #333;
  font-size: 0.92857rem;
}

.l-main .news-wrap .c-roundbox .c-title.has-en span {
  display: inline-block;
  padding-bottom: 2px;
  color: #2d720a;
  font-family: "Ubuntu", "游ゴシック体", YuGothic, "Yu Gothic Medium", "Yu Gothic UI", sans-serif;
  font-size: 2.14286rem;
  font-weight: bold;
  font-style: italic;
  vertical-align: middle;
  margin-right: 5px;
}

.l-main .news-wrap .btn-plus img {
  width: 21px;
}

.l-main .news-wrap .btn-plus:hover {
  cursor: pointer;
}

.l-main .li-news {
  border-top: 1px solid #f4f4f4;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}

.l-main .li-news li {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 12px 32px 10px;
  border-bottom: 1px solid #f4f4f4;
}

.l-main .li-news li:before {
  position: absolute;
  display: block;
  content: "";
}

.l-main .li-news li:before {
  top: 20px;
  left: 17px;
  width: 6px;
  height: 6px;
  background: url(../img/common/icon_arrow_r_gr.png) no-repeat center center/contain;
}

.l-main .li-news .txt-date {
  margin-right: 10px;
  font-family: "Ubuntu", "游ゴシック体", YuGothic, "Yu Gothic Medium", "Yu Gothic UI", sans-serif;
  color: #2d720a;
  font-weight: bold;
  font-style: italic;
}

.l-main .li-news .ttl {
  width: 100%;
  margin-top: 5px;
}

.l-main .pickup-wrap {
  padding: 10px 0;
}

.l-main .pickup-wrap .c-title {
  margin-bottom: 15px;
  font-family: "Ubuntu", "游ゴシック体", YuGothic, "Yu Gothic Medium", "Yu Gothic UI", sans-serif;
  font-size: 1.71429rem;
}

.l-main .pickup-wrap .c-title img {
  width: 10px;
}

.l-main .pickup-wrap .slick-prev.slick-arrow {
  left: 12px;
}

.l-main .pickup-wrap .slick-next.slick-arrow {
  right: 12px;
}

.l-main .various-wrap {
  padding: 95px 0 50px;
}

.l-main .various-wrap .c-roundbox {
  padding-bottom: 60px;
}

.l-main .various-wrap .c-title {
  margin-bottom: 20px;
}

.l-main .various-wrap .li-bnr li + li {
  margin-top: 15px;
}

.l-main .app-wrap .c-roundbox {
  padding-bottom: 40px;
}

.l-main .app-wrap .li-app {
  margin: 20px -15px;
  border-top: 1px solid #cbdfc0;
}

.l-main .app-wrap .li-app li {
  border-bottom: 1px solid #cbdfc0;
}

.l-main .app-wrap .li-app li a {
  display: flex;
  align-items: center;
  padding: 15px 20px;
}

.l-main .app-wrap .li-app li .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fffde4;
}

.l-main .app-wrap .li-app li .icon img {
  max-width: 29px;
  max-height: 29px;
}

.l-main .app-wrap .li-app li .text {
  font-weight: bold;
  padding-left: 15px;
}

/* ▼モバイルのみ付け足すデザイン */
/* ▼タブレット用デザインとして付け足すデザイン */
@media all and (min-width: 768px) {
  .l-main .mv-wrap img {
    border-radius: 0 0 112px 0;
  }
  .l-main .search-wrap .c-columns {
    justify-content: space-between;
  }
  .l-main .search-wrap .c-columns > .c-roundbox {
    width: calc((100% - 40px) / 3);
  }
  .l-main .search-wrap .c-btn {
    display: block;
    max-width: 188px;
    margin-left: auto;
    margin-right: auto;
  }
  .l-main .infomation-wrap .li-infomation {
    display: flex;
    flex-wrap: wrap;
    margin: -30px 0 0 -30px;
  }
  .l-main .infomation-wrap .li-infomation li {
    width: calc(50% - 30px);
    margin: 30px 0 0 30px;
  }
  .l-main .various-wrap .li-bnr {
    flex-wrap: nowrap;
    margin: 0 0 0 -16px;
  }
  .l-main .various-wrap .li-bnr li {
    flex: 1;
    margin-left: 16px;
  }
  .l-main .various-wrap .li-bnr li + li {
    margin-top: 0;
  }
  .l-main .app-wrap .li-app {
    display: flex;
  }
  .l-main .app-wrap .li-app li {
    flex: 1;
    border: none;
  }
  .l-main .app-wrap .li-app li a {
    display: block;
    text-align: center;
  }
  .l-main .app-wrap .li-app li a:after {
    content: none;
  }
  .l-main .app-wrap .li-app li .icon {
    margin: auto;
  }
  .l-main .app-wrap .li-app li .text:after {
    display: inline-block;
    content: "";
    width: 21px;
    height: 21px;
    margin-top: -3px;
    margin-left: 5px;
    border: 1px solid #abd298;
    border-radius: 50%;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212px%22%20height%3D%2212px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(45%2C%20114%2C%2010)%22%20d%3D%22M11.991%2C6.001%20L-0.007%2C11.993%20L-0.007%2C0.009%20L11.991%2C6.001%20Z%22%2F%3E%3C%2Fsvg%3E") no-repeat center 48%/6px 6px;
    vertical-align: middle;
  }
}

/* ▼PC用デザインとして付け足すデザイン */
@media all and (min-width: 960px) {
  .l-main .mv-wrap {
    margin-top: 15px;
    padding-top: 16px;
    margin-bottom: 36px;
  }
  .l-main .mv-wrap .slick-slide {
    padding: 0 8px;
  }
  .l-main .search-wrap {
    padding: 20px 0 25px;
  }
  .l-main .search-wrap .c-roundbox {
    flex: none;
    padding: 15px 15px 0;
  }
  .l-main .search-wrap .c-roundbox .c-title {
    border: none;
    text-align: center;
  }
  .l-main .search-wrap .c-roundbox .c-title .icon {
    display: block;
    margin-bottom: 15px;
  }
  .l-main .search-wrap .c-roundbox .c-title .icon img {
    max-width: 38px;
    max-height: 31px;
  }
  .l-main .search-wrap .c-roundbox .label-search span {
    font-size: 1rem;
  }
  .l-main .search-wrap .c-roundbox .label-search input {
    font-size: 1.14286rem;
  }
  .l-main .search-wrap .c-roundbox .label-search.departure {
    margin-bottom: 42px;
  }
  .l-main .search-wrap .c-roundbox .label-search.departure:after {
    left: 50%;
    transform: translateX(-50%);
    width: 16px;
    height: 40px;
  }
  .l-main .search-wrap .box-trafic.c-roundbox {
    width: 352px !important;
  }
  .l-main .search-wrap .box-route, .l-main .search-wrap .box-sa {
    width: 284px !important;
  }
  .l-main .search-wrap .c-roundbox .btn-wrap {
    text-align: center;
  }
  .l-main .search-wrap .c-roundbox .c-btn {
    width: 188px;
    height: 48px;
    padding: 0;
    line-height: 48px;
  }
  .l-main .search-wrap .c-roundbox .c-btn.is-sizeS {
    width: 118px;
    height: 34px;
    margin-right: 0;
    line-height: 34px;
  }
  .l-main .search-wrap .c-roundbox .li-servicearea .label-radio {
    font-size: 1rem;
  }
  .l-main .search-wrap .c-roundbox .c-searchWrap {
    display: block;
  }
  .l-main .search-wrap .c-roundbox .c-searchWrap .c-search {
    width: 100%;
    height: 48px;
    padding: 10px 20px;
  }
  .l-main .search-wrap .c-roundbox .li-trafic {
    margin-top: -10px;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li a {
    padding: 7px 0 10px;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li a:after {
    content: none;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li .txt-mark img {
    width: 32px;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li .txt-area {
    margin-top: 5px;
    font-size: 1rem;
    text-align: center;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li .txt-area:after {
    display: inline-block;
    content: "";
    right: 5px;
    bottom: 5px;
    width: 21px;
    height: 21px;
    margin-left: 5px;
    border: 1px solid #cbdfc0;
    border-radius: 50%;
    background: url(../img/common/icon_window_gr.png) no-repeat center center/9px 9px;
    vertical-align: bottom;
  }
  .l-main .search-wrap .c-roundbox .box-trafic .txt {
    font-size: 1rem;
  }
  .l-main .search-wrap .c-roundbox .box-trafic .txt-date {
    font-size: 1rem;
    font-weight: bold;
    font-style: italic;
  }
  .l-main .infomation-wrap {
    padding: 65px 0;
  }
  .l-main .infomation-wrap .c-roundbox {
    padding: 15px 35px 85px;
  }
  .l-main .infomation-wrap .c-roundbox .c-title {
    margin-bottom: 30px;
    margin-left: -35px;
    margin-right: -35px;
    padding: 10px 20px 20px;
    font-size: 1.85714rem;
  }
  .l-main .infomation-wrap .c-card .text {
    padding: 16px;
    font-size: 1.42857rem;
  }
  .l-main .news-wrap {
    padding: 62px 0 42px;
    background: #fff;
  }
  .l-main .news-wrap .c-title {
    font-size: 1.85714rem;
  }
  .l-main .news-wrap .c-roundbox {
    display: flex;
    padding: 0;
  }
  .l-main .news-wrap .c-roundbox .c-title.has-en {
    width: 218px;
    margin: 0;
    padding: 80px 0 0 35px;
    border-right: 1px solid #f4f4f4;
    font-size: 1.14286rem;
    font-style: normal;
  }
  .l-main .news-wrap .c-roundbox .c-title.has-en span {
    display: block;
    font-size: 3.42857rem;
  }
  .l-main .news-wrap .c-roundbox > div {
    padding-bottom: 25px;
  }
  .l-main .news-wrap .btn-plus {
    display: inline-block;
    padding-top: 15px;
  }
  .l-main .news-wrap .btn-plus img {
    width: 30px;
  }
  .l-main .li-news {
    margin: auto;
    padding: 40px 40px 0;
    border-top: none;
  }
  .l-main .li-news li {
    padding: 7px 0 7px 30px;
    border: none;
  }
  .l-main .li-news li:before {
    top: 18px;
  }
  .l-main .pickup-wrap {
    padding: 10px 0 45px;
    background: #fff;
  }
  .l-main .pickup-wrap .c-title {
    margin-bottom: 17px;
    font-size: 2.28571rem;
  }
  .l-main .pickup-wrap .c-title img {
    width: 13px;
  }
  .l-main .pickup-wrap .box-slide {
    width: calc((99.9999% - 114px )/ 3);
    padding: 0;
  }
  .l-main .various-wrap {
    padding: 65px 0 50px;
  }
  .l-main .various-wrap .c-roundbox {
    padding: 30px 30px 65px;
  }
  .l-main .various-wrap .c-roundbox .c-title {
    margin-bottom: 25px;
    font-size: 1.85714rem;
  }
  .l-main .app-wrap .c-roundbox {
    padding: 30px 30px 8px;
  }
  .l-main .app-wrap .c-roundbox .c-title {
    margin-bottom: 25px;
    border: none;
    font-size: 1.85714rem;
  }
  .l-main .app-wrap .c-roundbox .li-app {
    border: none;
  }
  .l-main .app-wrap .c-roundbox .li-app li .icon {
    width: 85px;
    height: 85px;
    margin-bottom: 15px;
  }
  .l-main .app-wrap .c-roundbox .li-app li .icon img {
    max-width: 30px;
    max-height: 44px;
  }
  .l-main .app-wrap .c-roundbox .li-app li .text:after {
    width: 30px;
    height: 30px;
  }
}
